.player::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 6px;
  background: #9aa7b4;
  box-shadow: -405px 0 0 400px #9aa7b4;
}

.player::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 6px;
  background: #9aa7b4;
  box-shadow: -405px 0 0 400px #9aa7b4;
}
