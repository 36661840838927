.timestamp-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 40px; 
    background: none;
    cursor: pointer;
  }
  
  .timestamp-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1px;
    height: 40px; /* Tall vertical stick */
    background: #1AD9EC;
    border:#1AD9EC;
    cursor: pointer;
  }
  
  .timestamp-slider::-moz-range-thumb {
    width: 1px;
    height: 40px;
    background: #1AD9EC;
    border:#1AD9EC;
    cursor: pointer;
  }
