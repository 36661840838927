@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@layer base {
  @font-face {
    font-family: Inter;
    font-weight: 100;
    src: url(/client/src/fonts/inter/Inter-Thin.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 200;
    src: url(/client/src/fonts/inter/Inter-ExtraLight.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 300;
    src: url(/client/src/fonts/inter/Inter-Light.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 400;
    src: url(/client/src/fonts/inter/Inter-Regular.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 500;
    src: url(/client/src/fonts/inter/Inter-Medium.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 600;
    src: url(/client/src/fonts/inter/Inter-SemiBold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 700;
    src: url(/client/src/fonts/inter/Inter-Bold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 800;
    src: url(/client/src/fonts/inter/Inter-ExtraBold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 900;
    src: url(/client/src/fonts/inter/Inter-Black.ttf) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: Roboto;
    font-weight: 100;
    src: url(/client/src/fonts/roboto/RobotoMono-Thin.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 200;
    src: url(/client/src/fonts/roboto/RobotoMono-ExtraLight.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 300;
    src: url(/client/src/fonts/roboto/RobotoMono-Light.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url(/client/src/fonts/roboto/RobotoMono-Regular.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 500;
    src: url(/client/src/fonts/roboto/RobotoMono-Medium.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 600;
    src: url(/client/src/fonts/roboto/RobotoMono-SemiBold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 700;
    src: url(/client/src/fonts/roboto/RobotoMono-Bold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 800;
    src: url(/client/src/fonts/roboto/RobotoMono-Bold.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: 900;
    src: url(/client/src/fonts/roboto/RobotoMono-Bold.ttf) format('truetype');
    font-display: swap;
  }

  html {
    font-family: 'Inter', sans-serif;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
